import React, {useState} from "react";
import "../../App.css";
import { ReactComponent as Chevron } from '../../img/chevron.svg';


const faq = [
    {
        id: 0,
        question: 'В чем принципиальное отличие Вашей компании от других смс - агрегаторов?',
        answer: 'В отличие от других подобных компаний, мы являемся разработчиками и продаем собственные услуги.А не занимаемся ресейлом чужих услуг'
    },
    {
        id: 1,
        question: 'Как давно Ваша компания на рынке?',
        answer: 'В 2024 году нашей компании исполняется 10 лет. И мы прошли путь от обычного реселлера до собственного отдела разработки по всем продуктам'
    },
    {
        id: 2,
        question: 'Что такое альтернативные каналы? Почему Ваши цены на рассылку сильно дешевле других компаний?',
        answer: 'Мы выбрали для себя путь создания аналогов официальным каналам. Без потери качества и более широкими возможностями для клиентов'
    },
    {
        id: 3,
        question: 'В чем преимущества альтернативных каналов?',
        answer: 'Преимуществ множество. Это гораздо более доступная цена, это возможность рассылок без согласований множества инстанций своего имени, тематики. Возможность рассылки на Гемблинг, Беттинг, Алкоголь и т. п. Все то, что не получится рассылать в официальных каналах'
    },
    {
        id: 4,
        question: 'То есть Вы берете в рассылку абсолютно все тематики, которые возможно?',
        answer: 'Нет, категорически нет. Мы работаем в правовом поле по законам Российской Федерации и не берем в работу никакие тематики, нарушающие законы Российской Федерации'
    },
    {
        id: 5,
        question: 'Ну в чем - то же есть недостаток таких каналов?',
        answer: 'Недостаток альтернативных каналов безусловно есть. И основной недостаток - это лимитированный объем сообщений. Но наша команда день ото дня развивает все наши продукты так, чтобы клиент все меньше чувствовал, что каналы имеют лимит'
    },
    {
        id: 6,
        question: 'Что делать, если хочу с Вами работать, но у меня нет своей базы номеров?',
        answer: 'Конечно мы рекомендуем клиентам самим решить вопрос с базой. Но также у нас есть понимание, в каких источниках искать базу клиенту и мы можем Вас направить в нужное направление. Чтобы Вы обзаведись базой и мы начали сотрудничество'
    },
    {
        id: 7,
        question: 'Я хочу сделать рассылку по мессенджерам, но я не уверен, что все мои получатели имеют установленный WhatsApp на своем номере телефона?',
        answer: 'Мы делаем валидацию базы на наличие нужного Вам мессенджера абсолютно бесплатно. И Вы сможете отдать в рассылку только ту базу, где установлен мессенджер на каждый номер абонента'
    }
];

export const Blog = () => {
    const [openItems, setOpenItems] = useState([
        {id: 0, open: false},
        {id: 1, open: false},
        {id: 2, open: false},
        {id: 3, open: false},
        {id: 4, open: false},
        {id: 5, open: false},
        {id: 6, open: false},
        {id: 7, open: false}
    ]);
    const handleQuestionLineClick = (id) => () => {
        console.log(id);
        const newOpenItems = openItems.map(item => {
            if(item.id === id) {
               return {...item, open: !item.open};
            }
            return {...item};
        })
        setOpenItems(newOpenItems);
    };
    return (
        <div className={'faqPage'}>
            <div className={'faqText'}>FAQ</div>
            <div className={'faqInfo'}>
                {faq.map(item => <div className={'faqItem'} onClick={handleQuestionLineClick(item.id)}>
                    <div className={'faqQuestionLine'}>
                        <div>{item.question}</div>
                        <div><Chevron className={openItems[item.id].open ? 'chevronRotate' : 'chevron'}/></div>
                    </div>
                    {openItems[item.id].open && <div className={'faqAnswerLine'}>{item.answer}</div>}
                </div>)}
            </div>
        </div>
    );
};