import React from "react";
import { Range } from 'react-range';
import {items} from "../../items";
import "./Prices.css";
import {useTranslation} from "react-i18next";


export const Prices = (props) => {
    const [currentPriceItem, setCurrentPriceItem] = React.useState(items[0]);
    const [values, setValues] = React.useState([0]);
    const { t } = useTranslation();
    const showedPriceOfOneItem = values <= currentPriceItem.priceItems[0].max ? currentPriceItem.priceItems[0].price : (values <= currentPriceItem.priceItems[1].max ? currentPriceItem.priceItems[1].price : currentPriceItem.priceItems[2].price);
    const showedPrice = values * showedPriceOfOneItem;
    return (
        <div className={'pricePage'}>
            <div className={'pricesItems'}>
                {items.map(item => <div className={currentPriceItem.id === item.id ? 'selectedPriceItem' : 'priceItem'} onClick={() => {setCurrentPriceItem(item); setValues([0])}}>{item[t("prices.titleField")]}</div>)}
            </div>
            <div>
            <div className={'priceText'}>{currentPriceItem[t("prices.titleField")]}</div>
            <div className={'priceFormat'}>{currentPriceItem[t("prices.formatField")]}</div>
                <div className="ptable">
                    <div className="prow">
                        <div className="pcell pcellleft">{t('prices.amount')}</div>
                        {currentPriceItem.priceItems.map(item => <div className="pcell pcellhead">{item.min} - {item.max}</div>)}
                    </div>
                    <div className="prow">
                        <div className="pcell pcellleft">{t('prices.price')}</div>
                        {currentPriceItem.priceItems.map(item => <div className="pcell pcellmain">{item.price}</div>)}
                    </div>
                </div>
                {values[0] === 0 ? (
                    <div className={'priceInfo'}><div className={'priceInfoEmpty'}>{t('prices.emptyText')}</div></div>
                ) : (
                    <div className={'priceInfo'}>
                        <div className={'priceInfoItem'}>{values} {t('prices.pcs')}</div>
                        <div className={'priceInfoItem'}>{t('prices.priceDots')} {showedPrice} {t('prices.rubles')}</div>
                    </div>
                )}
            <div className={'priceRange'}>
                <Range
                    step={1000}
                    min={0}
                    max={currentPriceItem.priceItems[2].max}
                    values={values}
                    onChange={(values) => setValues(values)}
                    renderTrack={({ props, children }) => (
                        <div
                            {...props}
                            style={{
                                ...props.style,
                                height: '6px',
                                width: '100%',
                                backgroundColor: '#337ab7'
                            }}
                        >
                            {children}
                        </div>
                    )}
                    renderThumb={({ props }) => (
                        <div
                            className={'thumb'}
                            {...props}
                            style={{
                                ...props.style,
                                height: '42px',
                                width: '42px',
                                backgroundColor: 'transparent',
                                border: 'none',
                            }}
                        />
                    )}
                />
            </div>
                <div className={"priceMarks"}>
                    <div>0</div>
                    <div>{currentPriceItem.priceItems[2].max}</div>
                </div>
            </div>
        </div>
    );
};