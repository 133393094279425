import React from "react";
import "../../App.css";


const aboutItems = [
    {year: '2014 год.', text: 'Год создания компании. Начинаем деятельность с ресселерства услуг sms - рекламы.'},
    {year: '2016 год.', text: 'Создание собственной платформы по sms - рассылкам. Первые прямые контракты с операторами связи.'},
    {year: '2017 год.', text: 'Открытие собственного производства. Создание собственной sms - фермы для  рассылок. Начало пути работы в альтернативных каналах sms рекламы.'},
    {year: '2018 год.', text: 'Создание собственного сервиса по рассылкам WhatsApp.'},
    {year: '2020 год.', text: 'Создание собственного сервиса по рассылке Viber.'},
    {year: '2021 год.', text: 'Создание собственного сервиса sms активаций. Создание собственного сервиса по рассылке Telegram'},
    {year: '2023 год.', text: 'Создание собственного сервиса по рассылке IMO'},
    {year: '2024 год.', text: 'Создание API для рассылок во всех мессенджерах.'},
    {year: 'По состоянию на 2024 год компания Sms - Retail абсолютный лидер по объему sms трафика и трафика в мессенджерах в России и СНГ, по альтернативным каналам рассылок. Пройден путь от ресселерства до собственного производства абсолютно всех продуктов. Сотрудничая с нашей компанией вы получите безупречный сервис, лучшее соотношение цены и качества предоставляемый услуг, полное соблюдение NDA и сохранности баз.'}
]
export const About = () => {
    return (
        <div className={'aboutPage'}>
            <div className={'aboutH'}>О Нас</div>
            <div className={'aboutInfo'}>
                {
                    aboutItems.map(item => (
                        <div className={'aboutItem'}>
                            <div className={'aboutYear'}>{item.year}</div>
                            <div className={'aboutText'}>{item.text}</div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};
