import React from "react";
import "../../App.css";
import { useTranslation } from 'react-i18next';

export const Footer = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <div id="contact">
        <div className="container contact-bottom">
          <div className="contact-info ">
            <div className="contactitem">
                <h3>
                  {t('contaсtInfo')}
                </h3>
            </div>
            <div className="contact-item">
              <p className="contactitem">
                <span>
                  {t('address')}
                </span>
                {t('addressInfo')}
              </p>
            </div>
            <div className="contact-item">
              <p className="contactitem">
                <span>
                  {t('phone')}
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p className="contactitem">
                <span>
                  {t('email')}
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2014-2024 SMS Retail
          </p>
        </div>
      </div>
    </div>
  );
};
